import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState, lazy,Suspense,startTransition } from "react";
import { Route, Router, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { onMessageListener } from "./firebase";
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
const Signup = lazy(() => import("./Pages/Signup"));
const Login = lazy(() => import("./Pages/Login"));
const LandingPage = lazy(() => import("./Pages/LandingPage"));
const UserHome = lazy(() => import("./Pages/UserHome"));
const Edit = lazy(() => import("./Pages/Edit"));
const Shop = lazy(() => import("./Pages/Shop"));
const Calculator = lazy(() => import("./Pages/Calculator"));
const Company = lazy(() => import("./Pages/Company"));
const MyParcel = lazy(() => import("./Pages/MyParcel"));
const Sidebar = lazy(() => import("./Pages/Sidebar"));
const AdminDashboard = lazy(() => import("./Pages/AdminDashboard"));
const Customers = lazy(() => import("./Pages/Customers"));
const Detail = lazy(() => import("./Pages/Detail"));
const Profile = lazy(() => import("./Pages/Profile"));
const SuperAdminDashboard = lazy(() => import("./Pages/SuperAdmin/SuperAdminDashboard"));
const SuperAdminSidebar = lazy(() => import("./components/SuperAdminSidebar"));
const SuperAddAdmins = lazy(() => import("./Pages/SuperAdmin/SuperAddAdmins"));
const SuperAddBrands = lazy(() => import("./Pages/SuperAdmin/SuperAddBrands"));
const SuperAddMargin = lazy(() => import("./Pages/SuperAdmin/SuperAddMargin"));
const Alert = lazy(() => import("./components/Alert"));
const BarCode = lazy(() => import("./Pages/BarCode"));
const UploadParcel = lazy(() => import("./Pages/UploadParcel"));
const ParcelTable = lazy(() => import("./components/ParcelTable"));
const FeedbackForm = lazy(() => import("./Pages/FeedBackForm"));
const ResetPassword = lazy(() => import("./Pages/reset-password"));
const HotDeals = lazy(() => import("./Pages/HotDeals"));
const FeedbackScreen = lazy(() => import("./Pages/FeedbackScreen"));
const Loader = lazy(() => import("./components/Loader"));

const Invoice = lazy(()=>import("./Pages/Invoice"))


function App() {
 useEffect(()=>{
  onMessageListener()
    .then((payload) => {
      startTransition(() => {
        displayBrowserNotification(
          payload.notification.title,
          payload.notification.body
        );
      });
    })
    .catch((err) => {
      console.log("here bro");
      console.log("err", err);
    });
 },[])
 function displayBrowserNotification(title, body) {
  // Check if the browser supports notifications
  if (!("Notification" in window)) {
    console.log("This browser does not support system notifications");
    return;
  }

  // Check if the user has granted permission to show notifications
  if (Notification.permission === "granted") {
    // Create and show the notification
    var notification = new Notification(title, { body: body });
    console.log(notification);
  } else if (Notification.permission !== "denied") {
    // Ask the user for permission to show notifications
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        // Create and show the notification
        new Notification(title, { body: body });
      }
    });
  }
}
  return (
    // <Signup/>
    <>
      <ToastContainer />
      <Suspense fallback={<div className="absolute inset-0 z-50 flex justify-center items-center backdrop-blur-lg">
      <div className="w-[50%]  flex justify-center items-center h-10 ">
        <div
          style={{ width: `100px`, height: `100px` }}
          className="animate-spin"
        >
          <div
            className="h-full w-full border-5 border-t-[#016072] 
       border-b-[#016072] rounded-[50%]"
          ></div>
        </div>
      </div>
    </div>} >
      <Routes>
        {/* <Route path="/" element={<Sidebar />}/> */}
        <Route path="/Signup" element={<Signup  />} />
        <Route path="/Login" element={<Login  />} />
        <Route exact path="/Home" element={<UserHome />} />
        <Route exact path="/edit" element={<Edit  />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/calculator" element={<Calculator />} />
        <Route exact path="/company" element={<Company />} />
        <Route exact path="/parcel" element={<MyParcel />} />

        <Route exact path="/AdminDashboard" element={<AdminDashboard />} />
        <Route exact path="/customers" element={<Customers />} />
        <Route exact path="/detail" element={<Detail />} />
        <Route exact path="/UploadParcel" element={<UploadParcel />} />
        <Route exact path="/adminprofile" element={<Profile  />} />
        
        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/SuperAdminDashboard"
          element={<SuperAdminDashboard />}
        />
        <Route exact path="/addadmin" element={<SuperAddAdmins  />} />
        <Route exact path="/addbrand" element={<SuperAddBrands />} />
        <Route exact path="/brcode" element={<BarCode />} />
        <Route path="/ParcelTable" element={<ParcelTable/>}  />
        <Route path="/FeedbackForm" element={<FeedbackForm/>}  />

        <Route
          exact
          path="/reset-password/:userId/:token"
          element={<ResetPassword />}
        />

        <Route path="/HotDeals" element={<HotDeals/>}  />
        <Route path="/FeedbackScreen" element={<FeedbackScreen/>}  />
        <Route path="/addMargin" element={<SuperAddMargin/>}  />
        <Route path="/Invoice" element={<Invoice/>}  />

        
      </Routes>
      </Suspense>
    </>
  );
}

export default App;
