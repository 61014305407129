// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getMessaging } from "firebase/messaging";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDjEysWzQbBNLIuEXZtdZbPH5eWs9fjsco",
  authDomain: "jongle-611f4.firebaseapp.com",
  projectId: "jongle-611f4",
  storageBucket: "jongle-611f4.appspot.com",
  messagingSenderId: "1059946785288",
  appId: "1:1059946785288:web:94ce8ebc5505896079bfbd",
  measurementId: "G-VZBJ08HTJB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });